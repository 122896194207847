




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ContactTypeSelect from '../ContactTypeSelect.vue';
import { Contact } from '../../types';
import { ApiResponse } from '@/components/types';

@Component({
  components: {
    ContactTypeSelect
  }
})
export default class UpdateAuthorityDialog extends Vue {
  @Prop({ type: Object }) value!: Contact | null;
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Boolean, default: true }) hasAdditional!: boolean;
  @Prop({ type: Function, required: true }) updateAuthority!: (params: any) => Promise<ApiResponse>;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  error: any = null;
  authority: Contact | null = null;

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value: boolean) {
    this.$emit('update:dialog', value);
  }

  @Watch('value', { immediate: true })
  watchDialogClosed(value: Contact | null) {
    if (!value) {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;

      return;
    }

    this.authority = { ...value };
  }

  save() {
    if (!this.authority) return {};

    this.error = null;

    return this.updateAuthority(this.authority);
  }
}
