








import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UPDATE } from '@/modules/court/store';
import UpdateAuthorityDialog from './UpdateAuthorityDialog.vue';
import { Contact, WriteContact } from '../../types';
import { WriteCourt } from '../../../court/types';
import { ApiResponse } from '@/components/types';
import { MapContactToWriteContact, MapWriteContactToWriteCourt } from '../../model';
import { CHANGE_CONTACT } from '@/modules/contact/store';

const Court = namespace('court');
const Contact = namespace('contact');

@Component({
  components: { UpdateAuthorityDialog }
})
export default class UpdateCourtDialog extends Vue {
  @Prop({ type: Object }) value!: Contact | null;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  @Court.Action(UPDATE) updateCourt!: (params: WriteCourt) => Promise<ApiResponse>;
  @Contact.Action(CHANGE_CONTACT) updateContact!: (params: WriteContact) => Promise<ApiResponse>;

  get court() {
    if (!this.value) return null;

    return MapContactToWriteContact(this.value);
  }

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value: boolean) {
    this.$emit('update:dialog', value);
  }

  async update(value: WriteContact) {
    const { error } = await this.updateContact(value);
    const { error: courtError } = await this.updateCourt(MapWriteContactToWriteCourt(value));

    return { error: error || courtError };
  }
}
